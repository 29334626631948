@use "/src/styles/mixins";

.navigation_list {
	padding: 0;
	list-style: none;

	@include mixins.breakpoint-mobile-up {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		align-items: center;
		column-gap: 30px;
		min-width: 192px;
	}

	@include mixins.breakpoint-mobile-down {
		margin-bottom: 25px;
	}

	li {
		@include mixins.breakpoint-mobile-down {
			margin-bottom: 15px;
		}
	}
}

.link {
    padding: 0;
    background: var(--lightColor);
    border: none;
    cursor: pointer;
}

.navigation_list a,
.link {
    font-size: 16px;
    line-height: 1.2;
    color: var(--textColor);
    text-decoration: none;
    transition: color 0.3s ease, font-size 0.7s ease;

    &:hover,
    &:focus,
    &:active {
        color: var(--activeColor);
    }
}

.active {
    color: var(--activeColor);
}

.button_wrapper {
    min-width: 192px;
}
