@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 300;
  line-height: 1;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  display: flex;
  align-items: center;
  text-align: center;
  fill: var(--lightColor);
  transform: translate(-20px, 14px);
}

.svg {
  margin: 0 auto 40px;
  overflow: visible;

   @include mixins.breakpoint-tablet-up {
    margin-bottom: 20px;
  }
}

.rect {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}

.label_element {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.active {
  .rect {
    opacity: 1;
  }

  .label_element {
    opacity: 1;
  }
}