@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 900;
  line-height: 1.2;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  fill: var(--lightColor);
  transform: translateY(36px);
}

.donut_svg {
  margin: auto;

  @include mixins.breakpoint-mobile-up {
    max-height: 70vh;
  }
}

.percent_label {
  font-weight: 200;
  line-height: 1;
  fill: var(--lightColor);
  transform: translateY(-36px);
}

.path {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}

.active {
  opacity: 1;
}
