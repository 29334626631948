@use "/src/styles/mixins";

.header {
  position: fixed;
  width: 100%;
  max-width: 1240px;
  top: 0;
  z-index: 10;
}

.navbar {
  position: relative;
  padding-top: 60px;

  @include mixins.breakpoint-desktop-down {
    padding-top: 42px;
  }

  &:before {
    @include mixins.breakpoint-laptop-large-down {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 10px;
      height: 180px;
      background: linear-gradient(180deg, #181818 54.69%, rgba(0, 0, 0, 0.00) 100%);
      z-index: 0;
    }

    @include mixins.breakpoint-tablet-down {
      right: 0;
    }
  }
}

.navigation_list {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 0;
  margin: 0 30px;
  list-style-type: none;
  z-index: 1;
  
  @include mixins.breakpoint-laptop-down {
    gap: 30px;
  }

  @include mixins.breakpoint-tablet-down {
    justify-content: flex-start;
    margin: 0;
    overflow-y: hidden;
    overflow-x: scroll;
    transition: all 0.3s ease-in-out;
  }

  li:first-child {
    margin-left: 40px;

    @include mixins.breakpoint-laptop-large-down {
      margin-left: 100px;
    }
  }

  li:last-child {
    margin-right: 40px;

    @include mixins.breakpoint-laptop-down {
      margin-right: 30px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
  }
   
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: transparent;
  }
   
  &::-webkit-scrollbar-thumb {
    border: none;
    background-color: transparent;
    border-radius: 3px;
  }
}

.nav_link {
  font: 900 30px/1 "Rubik", sans-serif;
  letter-spacing: 0.05em;
  color: var(--lightColor);
  opacity: 0.3;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  @include mixins.breakpoint-desktop-down {
    font-size: 24px;
  }

  @include mixins.breakpoint-laptop-down {
    font-size: 18px;
  }

  &.active {
    font-size: 75px;
    opacity: 1;

    @include mixins.breakpoint-desktop-down {
      font-size: 60px;
    }
  
    @include mixins.breakpoint-laptop-down {
      font-size: 45px;
    }

    @include mixins.breakpoint-tablet-down {
      font-size: 18px;
    }
  }
}
