@use "/src/styles/mixins";
.path {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.active {
  opacity: 1;
}
