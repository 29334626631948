@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 900;
  line-height: 1.2;
  font-family:  "Rubik", sans-serif;
  fill: var(--lightColor);
}

.glass_svg {
  margin: 0 auto 20px;

  @include mixins.breakpoint-tablet-down {
    margin-bottom: 20px;
  }

  @include mixins.breakpoint-mobile-up {
    max-height: 50vh;
  }

  @include mixins.breakpoint-mobile-down {
    max-width: 115px;
  }
}

.masked_area {
  transform: scaleY(-1);
  transform-origin: center;
}
