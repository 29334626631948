@import url('https://fonts.googleapis.com/css2?family=Marmelad&family=Rubik:wght@300;900&family=Yanone+Kaffeesatz:wght@200;400&display=swap');

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

html { 
  overflow-y: scroll; 
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
  height: auto;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

#root, #__next {
  isolation: isolate;
}

:root {
  --textColor: #2B1708;
  --activeColor: #d86814;
  --lightColor: #FFFFFF;
  --borderColor: #E7E6E6;;
  --backgroundColor: #000000;
  --scrollbarTrack: #F1F1F1;
  --scrollbarThumb: #C1C1C1;
  --disabledColor: rgba(43, 23, 8, 0.3);
  --chartColor: #F7AD2A;
  --textLightColor: #C9C9C9;
  --statisricsScrollbarTrack: rgba(255, 255, 255, 0.15);
  --statisricsScrollbarThumb: rgba(255, 255, 255, 0.15);
}

body {
  font-size: 12px;
  font-family: 'Marmelad', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overscroll-behavior: none;
}

.no-scroll {
  overflow: hidden;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}
 
::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: var(--scrollbarTrack);
}
 
::-webkit-scrollbar-thumb {
  border: 2px solid var(--scrollbarTrack);
  background-color: var(--scrollbarThumb);
  border-radius: 5px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}