@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 900;
  line-height: 1.2;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  fill: var(--lightColor);
}

.donut_svg {
  margin: auto;

  @include mixins.breakpoint-laptop-large-up {
    max-width: 95%;
  }

  @include mixins.breakpoint-mobile-up {
    max-width: 300px;
    max-height: 50vh;
  }
}
