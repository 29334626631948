@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 900;
  line-height: 1.2;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  fill: var(--lightColor);
}

.shape_svg {
  margin: 0 auto 40px;

  @include mixins.breakpoint-tablet-down {
    margin-bottom: 20px;
  }
  
}

.square {
  @include mixins.breakpoint-laptop-large-up {
    max-width: 90%;
  }

  @include mixins.breakpoint-mobile-up {
    max-width: 220px;
    max-height: 47vh;
  }
}

.circle {
  @include mixins.breakpoint-laptop-large-up {
    max-width: 95%;
  }

  @include mixins.breakpoint-mobile-up {
    max-width: 230px;
    max-height: 50vh;
  }
}

.other {
  @include mixins.breakpoint-laptop-large-up {
    max-width: 100%;
  }

  @include mixins.breakpoint-mobile-up {
    max-width: 250px;
    max-height: 50vh;
  }
}

.masked_area {
  transform: scaleY(-1);
  transform-origin: center;
}
