.preloader {
	width: 100%;
	max-width: 300px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	margin: auto;
  }
  
  .preloader_image {
	max-width: 150px;
  }