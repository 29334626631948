@use "/src/styles/mixins";

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.modal_window {
  width: clamp(50%, 776px, 90%);
  padding: 72px 94px;
  position: fixed;
  background: var(--lightColor) url("../../images/kitty.png") bottom right 52px
    no-repeat;
  background-size: 106px 105px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: left;
  z-index: 2;

  @include mixins.breakpoint-tablet-down {
    max-height: 96vh;
    padding: 20px 15px;
    background-position: bottom right 10px;
    background-size: 53px auto;
    overflow-y: auto;
  }
}

.modal_content {
  font-size: 18px;
  line-height: 21px;

  p {
    margin-bottom: 12px;
  }
}

.modal_close {
  position: absolute;
  top: 27px;
  right: 26px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 3;

  @include mixins.breakpoint-tablet-down {
    top: 20px;
    right: 15px;
  }
}
