@use "/src/styles/mixins";

.item {
	position: relative;
	flex-shrink: 0;
	flex: 0 0 calc(16.66%);
	overflow: hidden;

	@include mixins.breakpoint-desktop_down {
		flex: 0 0 calc(25%);
	}

	@include mixins.breakpoint-tablet-down {
		flex: 0 0 calc(50%);
	}

	@include mixins.breakpoint-mobile-down {
		flex: 0 0 100%;
	}
}

.image_container {
	position: relative;
}

.front_image {
	position: relative;
	margin: auto;
	z-index: 0;
}

.back_image {
	position: absolute;
	top: 0;
	left: 50%;
	z-index: 0;
}

.details {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
	padding: 20px 30px 30px;
	position: absolute;
	height: 139px;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 0;
}

.more {
	width: 25px;
	height: 25px;
	padding: 0;
	margin-bottom: -5px;
	background: transparent;
	border: none;
	cursor: pointer;
}

.turn {
	width: 25px;
	height: 25px;
	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
}

.info {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px 26px;
	border-radius: 0px 0px 11px 11px;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);

	ul {
		list-style: none;
		padding: 0;
		font-size: 16px;
		line-height: 1.2;
		color: var(--lightColor);
	}
}