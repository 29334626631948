@use "/src/styles/mixins";

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0;
  list-style: none;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  background: var(--backgroundColor) url("../../images/wood-bg.jpg") repeat top center;
  background-size: 512px auto;
  background-attachment: fixed;

  @include mixins.breakpoint-tablet-down {
    padding-top: 30px;
  }
}

.infinite_scroll_wrapper {
  width: 100%;
}
