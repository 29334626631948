@use "/src/styles/mixins";

.up_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 40px;
  right: 25px;
  opacity: 0;
  background-color: var(--lightColor);
  border-radius: 6px;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
  z-index: -1;
  cursor: pointer;

  @include mixins.breakpoint-tablet-down {
    width: 40px;
    height: 40px;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    width: 35px;
    height: 20px;

    @include mixins.breakpoint-tablet-down {
      width: 14px;
      height: 8px;
    }
  }
}

.is_visible {
  opacity: 0.75;
  z-index: 20;
}