@use "/src/styles/mixins";

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 290px;
  padding: 60px 25px 40px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  color: var(--textColor);
  background-color: var(--lightColor);
  overflow-y: auto;
  z-index: 0;

  @include mixins.breakpoint-tablet-down {
    max-width: none;
    padding: 53px 20px 20px;
  }

  &.padded {
    @include mixins.breakpoint-tablet-down {
      padding-bottom: 53px;
    }
  }
}

.h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 14px;
}

.clear_filter {
  font-size: 12px;
  line-height: 14px;
  padding: 8px 14px;
  border: 1px solid var(--borderColor);
  border-radius: 3px;
  background-color: var(--scrollbarTrack);
  margin-bottom: 4px;
  cursor: pointer;
}

.filter_handler_buttons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  padding: 10px 0 15px;
  background-color: var(--lightColor);
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.25);
}
