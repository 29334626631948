@use "/src/styles/mixins";

.percent {
  display: block;
  font-weight: 900;
  line-height: 1.2;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.05em;
  fill: var(--lightColor);
  transform: translateY(14px);
}

.label {
  position: relative;
  font-weight: 200;
  line-height: 1;
  font-family: "Yanone Kaffeesatz", sans-serif;
  fill: var(--lightColor);
  transform: translateY(14px);
}

.shape_svg {
  margin: 0 auto 40px;
  overflow: visible;

  @include mixins.breakpoint-tablet-down {
    margin-bottom: 20px;
  }

  @include mixins.breakpoint-mobile-up {
    max-height: 50vh;
  }
}

.rect {
  opacity: 0.2;
  transition: opacity 0.2s ease-in-out;
}

.label_element {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.active {
  .rect {
    opacity: 1;
  }

  .label_element {
    opacity: 1;
  }
}